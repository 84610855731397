@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
@font-face {
  font-family: "Hatton-Bold";
  src: local("Hatton-Bold"), url("./assets/fonts/Hatton-Bold.otf");
}

@font-face {
  font-family: "Hatton-Medium";
  src: local("Hatton-Medium"), url("./assets/fonts/Hatton-Medium.otf");
}

@font-face {
  font-family: "Hatton-Ultralight";
  src: local("Hatton-Ultralight"), url("./assets/fonts/Hatton-Ultralight.otf");
}

@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"), url("./assets/fonts/PublicSans-Light.ttf");
}

@keyframes flicker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.cursor {
  display: inline-block;
  width: 1ch;
  animation: flicker 0.5s infinite;
  margin-bottom: 4px;
}

* {
  font-family: "Hatton-Ultralight";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-slidein600 {
  animation: slideIn 4s forwards;
}
.animate-slidein600-delay {
  animation: slideIn 3s forwards;
  animation-delay: 1s;
}

@keyframes slideIn {
  from {
    transform: translateY(10vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.bg-parallax {
  transition: transform 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.bg-parallax.active {
  transition: transform 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.transition-colors {
  transition-property: color;
}

.black_button {
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #000 33%,
    #0000 33% 66%,
    #000 66%
  );
  background-position: right bottom;
  background-size: 300% 2px;
  background-repeat: no-repeat;
}
.white_button {
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #ffffff 33%,
    #ffffff00 33% 66%,
    #ffffff 66%
  );
  background-position: right bottom;
  background-size: 300% 2px;
  background-repeat: no-repeat;
}
.black_button:hover {
  background-position: left bottom;
  transition: background-position 1s;
}
.white_button:hover {
  background-position: left bottom;
  transition: background-position 1s;
}

/* seins.css */
.hr-lines-container {
  position: relative;
}

.hr-lines {
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.5;
  position: relative;
  padding-left: 125px;
  display: inline-block;
  white-space: nowrap;
}

.hr-lines:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1px;
  width: 90px;
  background: rgb(255, 255, 255);
  transition: width 0.3s ease, opacity 0.3s ease;
  opacity: 0.5;
}

.hr-lines.clicked:before {
  opacity: 1;
  width: 120px;
}

.hr-lines.clicked {
  opacity: 1;
}

@media (max-width: 640px) {
  .hr-lines {
    padding-left: 85px;
  }

  .hr-lines:before {
    width: 60px;
  }

  .hr-lines.clicked:before {
    width: 80px;
  }
}
