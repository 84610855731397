.to-top {
    position: absolute;
    animation: toTop 2s alternate;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.04,.52,.86,.13);
    animation-duration: 1.7s;
    width: 100vw;
  height: 140vh;
    bottom: 0;
    z-index: 5;
  }
  
  .text-container {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    align-items: center;
    height: 100%;
  }
  
  .to-top-text {
    position: absolute;
    opacity: 1; 
    animation: toTopText 1s alternate;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.59,.8,.23,.07);
    animation-duration: 1.4s;
    
    
  }
  
  @keyframes toTop {
    0% {
      transform: translateY(100vh);
    }
  
    100% {
      transform: translateY(-100vh);
    }
  }
  
  @keyframes toTopText {
    0% {
      opacity: 0;
      transform: translateY(0vh);
    }
    25%{
      opacity: 1;
      
    }
  
    100% {
      opacity: 0;
      transform: translateY(-20vh); 
    }
  }