.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  
.parallax .scroller {
    text-transform: uppercase;
    font-size: 34px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  
.parallax span {
    
    margin-right: 70px;
    word-spacing: 60px;
  }
